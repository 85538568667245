var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{ref:"box"},[_c('div',{ref:"header"},[_c('a-page-header',{staticStyle:{"border-bottom":"1px solid rgb(235, 237, 240)"},attrs:{"title":"三部曲管理"}})],1),_c('a-form',{staticStyle:{"margin-top":"24px"},attrs:{"form":_vm.form,"label-col":_vm.labelCol,"wrapper-col":_vm.wrapperCol},on:{"submit":_vm.handleSubmit}},[_c('a-form-item',{attrs:{"label":"视频1：","extra":"最多支持1个"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'one_url',
            {
                rules: [{ required: true, message: '请选择视频!' }],
            } ]),expression:"[\n            'one_url',\n            {\n                rules: [{ required: true, message: '请选择视频!' }],\n            },\n            ]"}],attrs:{"type":"hidden"}}),_c('a-upload',{staticClass:"upload-list-inline",attrs:{"name":"file","list-type":"picture","fileList":_vm.fileList,"customRequest":_vm.changeImg,"remove":_vm.removeImg,"beforeUpload":_vm.handleBeforeUpload},on:{"change":_vm.getchangeImg}},[_c('a-button',{attrs:{"disabled":!_vm.uploadBtn}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传视频 ")],1)],1)],1),_c('a-form-item',{attrs:{"label":"视频2：","extra":"最多支持1个"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'two_url',
            {
                rules: [{ required: true, message: '请选择视频!' }],
            } ]),expression:"[\n            'two_url',\n            {\n                rules: [{ required: true, message: '请选择视频!' }],\n            },\n            ]"}],attrs:{"type":"hidden"}}),_c('a-upload',{staticClass:"upload-list-inline",attrs:{"name":"file1","list-type":"picture","fileList":_vm.fileList1,"customRequest":_vm.changeImg1,"remove":_vm.removeImg1,"beforeUpload":_vm.handleBeforeUpload},on:{"change":_vm.getchangeImg1}},[_c('a-button',{attrs:{"disabled":!_vm.uploadBtn1}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传视频 ")],1)],1)],1),_c('a-form-item',{attrs:{"label":"视频3：","extra":"最多支持1个"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
            'three_url',
            {
                rules: [{ required: true, message: '请选择视频!' }],
            } ]),expression:"[\n            'three_url',\n            {\n                rules: [{ required: true, message: '请选择视频!' }],\n            },\n            ]"}],attrs:{"type":"hidden"}}),_c('a-upload',{staticClass:"upload-list-inline",attrs:{"name":"file2","list-type":"picture","fileList":_vm.fileList2,"customRequest":_vm.changeImg2,"remove":_vm.removeImg2,"beforeUpload":_vm.handleBeforeUpload},on:{"change":_vm.getchangeImg2}},[_c('a-button',{attrs:{"disabled":!_vm.uploadBtn2}},[_c('a-icon',{attrs:{"type":"upload"}}),_vm._v(" 上传视频 ")],1)],1)],1),_c('a-form-item',{attrs:{"wrapper-col":{ span: 14, offset: 4 }}},[_c('a-button',{attrs:{"type":"primary","html-type":"submit"}},[_vm._v(" 保存 ")])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }