<template>
    <div class="container">
        <div ref="box">
        <div ref="header">
            <a-page-header
            style="border-bottom: 1px solid rgb(235, 237, 240)"
            title="三部曲管理"
            >
            <!-- <template slot="extra">
                <a-button
                class="editable-add-btn"
                @click="handleAdd"
                size="small"
                >
                新增
                </a-button>
            </template> -->
            </a-page-header>
        </div>
        <a-form
            :form="form"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
            @submit="handleSubmit"
            style="margin-top: 24px"
        >
            <a-form-item label="视频1：" extra="最多支持1个">
            <a-input
                v-decorator="[
                'one_url',
                {
                    rules: [{ required: true, message: '请选择视频!' }],
                },
                ]"
                type="hidden"
            ></a-input>
            <a-upload
                name="file"
                list-type="picture"
                class="upload-list-inline"
                :fileList="fileList"
                :customRequest="changeImg"
                @change="getchangeImg"
                :remove="removeImg"
                :beforeUpload="handleBeforeUpload"
            >
                <a-button :disabled="!uploadBtn">
                <a-icon type="upload" /> 上传视频
                </a-button>
            </a-upload>
            </a-form-item>
            <a-form-item label="视频2：" extra="最多支持1个">
            <a-input
                v-decorator="[
                'two_url',
                {
                    rules: [{ required: true, message: '请选择视频!' }],
                },
                ]"
                type="hidden"
            ></a-input>
            <a-upload
                name="file1"
                list-type="picture"
                class="upload-list-inline"
                :fileList="fileList1"
                :customRequest="changeImg1"
                @change="getchangeImg1"
                :remove="removeImg1"
                :beforeUpload="handleBeforeUpload"
            >
                <a-button :disabled="!uploadBtn1">
                <a-icon type="upload" /> 上传视频
                </a-button>
            </a-upload>
            </a-form-item>
            <a-form-item label="视频3：" extra="最多支持1个">
            <a-input
                v-decorator="[
                'three_url',
                {
                    rules: [{ required: true, message: '请选择视频!' }],
                },
                ]"
                type="hidden"
            ></a-input>
            <a-upload
                name="file2"
                list-type="picture"
                class="upload-list-inline"
                :fileList="fileList2"
                :customRequest="changeImg2"
                @change="getchangeImg2"
                :remove="removeImg2"
                :beforeUpload="handleBeforeUpload"
            >
                <a-button :disabled="!uploadBtn2">
                <a-icon type="upload" /> 上传视频
                </a-button>
            </a-upload>
            </a-form-item>
            <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
            <a-button type="primary" html-type="submit">
                保存
            </a-button>
            </a-form-item>
        </a-form>
        </div>
    </div>
</template>
<script>
export default {
  name: "Car",
  data() {
    return {
      form: this.$form.createForm(this, { name: "deviceSearch" }),
      fileList: [],
      fileList1: [],
      fileList2: [],
      uploadBtn1: true,
      uploadBtn: true,
      uploadBtn2: true,
      labelCol: { span: 3 },
      wrapperCol: { span: 16 },
      canUpload:"",
    };
  },
  mounted() {
    // window.addEventListener("resize", () => this.changeTableH(), false);
    this.getSearchList();
  },
  methods: {
    //获取列表
    getSearchList(data) {
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.axios
        .get("/admin/AppUseThreeSteps/one", {
          params: data,
        })
        .then((res) => {
          that.$message.loading({
            content: "加载中..",
            key: "load",
            duration: 0.00001,
          });
          if (that.$code(res)) {
            if (res.data.data.one_url) {
              that.fileList = [
                {
                  uid: "-1",
                  name: "",
                  status: "done",
                  url: res.data.data.one_url,
                  thumbUrl: res.data.data.one_url,
                },
              ];
            } else {
              that.fileList = [];
            }
            if (res.data.data.two_url) {
              that.fileList1 = [
                {
                  uid: "-1",
                  name: "",
                  status: "done",
                  url: res.data.data.two_url,
                  thumbUrl: res.data.data.two_url,
                },
              ];
            } else {
              that.fileList1 = [];
            }
            if (res.data.data.three_url) {
              that.fileList2 = [
                {
                  uid: "-1",
                  name: "",
                  status: "done",
                  url: res.data.data.three_url,
                  thumbUrl: res.data.data.three_url,
                },
              ];
            } else {
              that.fileList2 = [];
            }
            if (res.data.data.one_url) {
              that.uploadBtn = false;
            } else {
              that.uploadBtn = true;
            }
            if (res.data.data.two_url) {
              that.uploadBtn1 = false;
            } else {
              that.uploadBtn1 = true;
            }
            if (res.data.data.three_url) {
              that.uploadBtn2 = false;
            } else {
              that.uploadBtn2 = true;
            }
            that.form.setFieldsValue({
              three_url: res.data.data.three_url,
              two_url: res.data.data.two_url,
              one_url: res.data.data.one_url,
            });
            // var trilogyList = res.data.data;
            // for (var i = 0; i < trilogyList.length; i++) {
            //   trilogyList[i].btns = ["编辑", "删除"];
            // }
            // that.trilogyList = trilogyList;
          }
        });

      that.$nextTick(() => {
        that.pageshow = true;
      });
    },
    //上传前校验
    handleBeforeUpload(file){
      if(file.type.indexOf("video")<0){
      this.$message.error("仅支持上传视频")
      this.canUpload=false
      return false;
      }else{
      this.canUpload=true
        return true
      }
    },
    //上传图片
    changeImg(info) {
      var that = this;
      this.name = info.file.name;
      var inputFile = info.file;
      const reader = new FileReader();
      reader.readAsDataURL(inputFile);
      reader.onloadend = function () {
        // var strBase64 = reader.result.substring(84);
        var strBase64 = reader.result.substring(0);
        that.dataURLtoBlob(strBase64, 0);
      };
    },
    changeImg1(info) {
      var that = this;
      this.name = info.file.name;
      var inputFile = info.file;
      const reader = new FileReader();
      reader.readAsDataURL(inputFile);
      reader.onloadend = function () {
        // var strBase64 = reader.result.substring(84);
        var strBase64 = reader.result.substring(0);
        that.dataURLtoBlob(strBase64, 1);
      };
    },
    changeImg2(info) {
      var that = this;
      this.name = info.file.name;
      var inputFile = info.file;
      const reader = new FileReader();
      reader.readAsDataURL(inputFile);
      reader.onloadend = function () {
        // var strBase64 = reader.result.substring(84);
        var strBase64 = reader.result.substring(0);
        that.dataURLtoBlob(strBase64, 2);
      };
    },
    dataURLtoBlob(dataurl, num) {
      var that = this;
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      that.uploadVideo(new Blob([u8arr], { type: mime }), num);
    },
    uploadVideo(info, num) {
      // console.log(URL.createObjectURL(info.file))
      var that = this;
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
      that.axios.post("/admin/upload/oss/getSignature").then((res) => {
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if (that.$code(res)) {
          var filedata = new FormData();
          var key =
            res.data.data.dir +
            new Date().getTime() +
            "_" +
            (Math.random() + "").substring(2, 7) +
            "_" +
            this.name;
          filedata.append("key", key);
          filedata.append("policy", res.data.data.policy);
          filedata.append("OSSAccessKeyId", res.data.data.accessid);
          filedata.append("signature", res.data.data.signature);
          filedata.append("success_action_status", 200);
          filedata.append("callback", res.data.data.callback);
          filedata.append("file", info);
          console.log(filedata)
      that.$message.loading({ content: "加载中..", key: "load",duration: 0 });
          that.axios
            .post(res.data.data.host, filedata, {
              headers: {
                processData: false,
                cache: false,
                contentType: false,
              },
            })
            .then((t) => {
              console.log(t)
        that.$message.loading({
          content: "加载中..",
          key: "load",
          duration: 0.00001,
        });
        if(t.data.code==200){
              if (num == 0) {
                that.form.setFieldsValue({
                  one_url: t.data.data.url,
                });
                that.fileList = [
                  {
                    uid: 0,
                    name: that.name,
                    status: "done",
                    url: t.data.data.url,
                    thumbUrl:t.data.data.url ,
                  },
                ];
              }
              if (num == 1) {
                that.form.setFieldsValue({
                  two_url: t.data.data.url,
                });
                that.fileList1 = [
                  {
                    uid: 1,
                    name: that.name,
                    status: "done",
                    url: t.data.data.url,
                    thumbUrl:t.data.data.url,
                  },
                ];
              }
              if (num == 2) {
                that.form.setFieldsValue({
                  three_url: t.data.data.url,
                });
                that.fileList2 = [
                  {
                    uid: 2,
                    name: that.name,
                    status: "done",
                    url: t.data.data.url,
                    thumbUrl:t.data.data.url,
                  },
                ];
              }
        }
            });
        }
      });
    },
    //删除图片
    removeImg() {
      this.fileList = [];
    },
    removeImg1() {
      this.fileList1 = [];
    },
    removeImg2() {
      this.fileList2 = [];
    },
    getchangeImg(info) {
      if(!this.canUpload){
        info.fileList=[]
      }
      if (info.fileList.length >= 1) {
        this.uploadBtn = false;
      } else {
        this.uploadBtn = true;
        if (info.fileList.length == 0) {
          this.form.setFieldsValue({
            one_url: "",
          });
        }
      }
    },
    getchangeImg1(info) {
      if(!this.canUpload){
        info.fileList=[]
      }
      if (info.fileList.length >= 1) {
        this.uploadBtn1 = false;
      } else {
        this.uploadBtn1 = true;
        if (info.fileList.length == 0) {
          this.form.setFieldsValue({
            two_url: "",
          });
        }
      }
    },
    getchangeImg2(info) {
      if(!this.canUpload){
        info.fileList=[]
      }
      if (info.fileList.length >= 1) {
        this.uploadBtn2 = false;
      } else {
        this.uploadBtn2 = true;
        if (info.fileList.length == 0) {
          this.form.setFieldsValue({
            three_url: "",
          });
        }
      }
    },
    handleSubmit(e) {
      e.preventDefault();
      var that = this;
      that.form.validateFields((error, values) => {
        if (!error) {
          var data = values;
          that.axios.post("/admin/AppUseThreeSteps/update", data).then((res) => {
            if (that.$code(res)) {
              that.$message.success("保存成功");
            }
          });
        }
      });
    },
  },
};
</script>
<style scoped>
table img {
  width: 100px;
}
.ant-table-wrapper {
  flex: 1;
}
.ant-modal-body form {
  overflow: hidden;
}
.ant-input-number {
  width: 100%;
}
.m-colorPicker {
  position: absolute;
  height: 39px;
  right: 0;
  width: 100%;
}
/deep/.m-colorPicker .colorBtn {
  margin-top: 8px;
  position: absolute;
  right: 9px;
  top: 5px;
}
/deep/.m-colorPicker .box {
  right: 0;
}
</style>